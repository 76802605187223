function PrivacyPolicy() {
  return (
    <div className="container policy">
      <h1>Privacy Policy</h1>

      <h3>1. Introduction and General Terms</h3>

      <p>
        Your privacy is important to us. It is our policy to respect your
        privacy regarding any information we may collect from you across our
        applications we own and operate, the "My Life in the UK"
        application(“App”) or our website mylifeintheuk.com(the “Website”)
        <a href="http://mylifeintheuk.com">http://mylifeintheuk.com</a>.
      </p>
      <p>
        The App and the Website is owned and operated by Sosimple Digital Ltd, a
        company registered in England and Wales under company number 10164358
        and with our registered office at Demsa Accounts, 278 Langham Road,
        London, United Kingdom, N15 3NP(“Sosimple digital ltd”, “we”, “us” or
        “our”).
      </p>
      <p>
        The App and / or Website may contain hyperlinks to websites owned and
        operated by third parties.They will govern the use of personal
        information you submit or which is collected by cookies whilst visiting
        these websites.We do not accept any responsibility or liability for the
        privacy practices of such third party websites and your use of them is
        at your own risk.
      </p>
      <p>
        Your continued use of our website will be regarded as acceptance of our
        practices around privacy and personal information.
      </p>
      <p>
        You are free to refuse our request for your personal information, with
        the understanding that we may be unable to provide you with some of your
        desired services.
      </p>

      <h3>2. Information we may collect about you</h3>
      <p>
        <b>We do not collect any personal data.</b>
        <br />
        <br />
        <b>Your Browsing Activities</b>
        <br />
        In relation to your activity on our online services, we may collect
        information relating to the web address that you arrive from or click
        through to, pages viewed, page response times, download errors, the
        length of time you spend on certain pages, page interaction information
        such as scrolling, clicks, and methods used to browse away from our
        Website.
        <br />
        <br />
        <b>How you use our App / Website(“Analytics”)</b>
        <br />
        We may collect technical information about your computer and / or device
        that may identify you, including your IP address, operating system,
        browser type, time - zone and browser plug -in details through third
        party analytics providers.These third party analytics providers may
        collect analytics on our behalf and in accordance with our instructions,
        and their applicable privacy notice.
        <br />
        <br />
        <b>
          Information about you collected from Third Parties(“Content
          Information”)
        </b>
        <br />
        We may collect information about you from third party service
        providers(like marketing partners or public databases) to use along with
        the information that we collect from you.
        <br />
        <br />
        Other companies may set their own cookies or similar technologies when
        you use the App and / or visit the Website.This includes third parties
        that we have engaged to provide services on our behalf(like website
        analytics), as well as third parties that deliver content(like videos
        from YouTube).
      </p>

      <h3>3. Why we collect information about you</h3>

      <p>
        We will use information about you for providing the App and delivering
        our services to you.
      </p>
      <p>
        We process information to understand how visitors use our App and/or
        Website. This processing is necessary for us to pursue our legitimate
        interest ofensuring that our App/Website functions properly so that you
        and other users have the best experience when using it, and improving
        the quality of our App/Website and providing a better experience to our
        users.
      </p>

      <h3>4. Data sharing</h3>

      <p>
        We will share your information with third parties only in the ways that
        are described in this privacy policy. If you would like to find out more
        about how the third parties listed in this policy use your information,
        this should be set out in their respective privacy policies.
      </p>
      <p>
        <b>Personnel, Suppliers or Subcontractors:</b> We keep your information
        confidential, but may disclose it to our personnel, suppliers or
        subcontractors insofar as it is reasonably necessary for the purposes
        set out in this privacy policy. However, this is on the basis that they
        do not make independent use of the information, and have agreed to
        safeguard this information.
      </p>
      <p>
        <b>Required by law:</b> In addition, we may disclose your information to
        the extent that we are required to do so by law (which may include to
        government bodies and law enforcement agencies); in connection with any
        legal proceedings or prospective legal proceedings; and in order to
        establish, exercise or defend our legal rights (including providing
        information to others for the purposes of fraud prevention).
      </p>
      <p>
        <b>Merger or acquisition:</b> If we are involved in a merger,
        acquisition, or sale of all or a portion of its assets, you will be
        notified via email, account message and/or a prominent notice on our
        website of any change in ownership or uses of this information, as well
        as any choices you may have regarding this information.
      </p>
      <p>
        <b>Enforcement:</b> We may also disclosure your personal information to
        third parties in order to enforce or apply the terms of agreements, to
        investigate potential breaches, or to protect our rights, property or
        safety or those of our customers or others.
      </p>

      <h3>5. Data retention</h3>
      <p>
        We will only hold data about you for as long as necessary, bearing in
        mind the purpose for which that data was collected.
      </p>

      <h3>
        6. Your rights in relation to personal data which we process relating to
        you
      </h3>
      <p>
        You have the right to access, modify, rectify and delete any data that
        concerns you over the way we process personal data relating to you. We
        aim to comply without undue delay, and within one month at the latest.
      </p>
      <p>To make a request, please contact us at theory-test@mylifeintheuk.com.</p>
      <p>
        If you do provide us with personal information we generally retain it
        for as long as your account on the Website and/or App is active, or as
        is otherwise necessary for us to provide the Website and/or App to you,
        or as otherwise described in this privacy policy.
      </p>

      <h3>7. Children</h3>
      <p>
        You should be at least 16 to use our App and register an account on App.
        We do not use the App to knowingly solicit data from or market to
        children under the age of 16. If a parent or guardian becomes aware that
        his or her child has provided us with information without their consent,
        he or she should contact us via the contact details detailed at the top
        of this privacy policy. We will delete such information from our files
        within a reasonable time.
      </p>

      <h3>8. Security</h3>
      <p>
        We will take reasonable physical, technical and organisational
        precautions to protect your personal information from unauthorised
        access, use or disclosure. For example, we use computer systems with
        limited access in controlled facilities to store personal info. We also
        use technologies like encryption and hashing to protect some of the info
        we collect.
      </p>
      <p>
        Please be aware that, although we endeavour to provide reasonable
        security for information we process and maintain, no security system can
        prevent all potential security breaches. By choosing to share your
        personal information with us you agree and accept that you do so at your
        own risk.
      </p>
      <br />
      <p className="opac">This policy is effective as of 10 August 2021.</p>
      <br />
      <br />
    </div>
  );
}

export default PrivacyPolicy;
