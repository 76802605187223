import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/styles/app-variables.css";
import "./assets/styles/fonts.css";
import "./assets/styles/index.css";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact={true}><Home /></Route>
          <Route path="/privacy-policy"><PrivacyPolicy /></Route>
          <Route path="/terms-of-service"><TermsOfService /></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
