import PngAppStore from './assets/images/appstore.png';
import PngPlayStore from './assets/images/google-play-badge.png';

function StoreIcons() {
    return (
        <div className="store-links">
        <a
          href="https://apps.apple.com/us/app/com-mylifeintheuk/id1579619467"
          className="store"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Download My Life in the UK"
            src={PngAppStore}
            className="store-img"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.mylifeintheuk"
          className="store"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Download My Life in the UK"
            src={PngPlayStore}
            className="store-img"
          />
        </a>
      </div>        
    )
}

export default StoreIcons;