import { MobileView } from "react-device-detect";
import AppIcon from "./assets/images/app-icon.png";
import SvgPlay from './assets/images/play.svg';
import "./assets/styles/app-variables.css";
import "./assets/styles/fonts.css";
import "./assets/styles/index.css";
import StoreIcons from "./StoreIcons";

function Home() {
  return (
    <div>
      <h1>Life in the UK Test</h1>
      <h2>in your language!</h2>

      <div className="languages">
        Albanian - Arabic - Bulgarian - Chinese - Danish - Dutch - French -
        German - Greek - Hungarian - Italian - Japanese - Korean - Kurdish -
        Lithuanian - Latvian - Persian - Polish - Portuguese - Romanian -
        Russian - Spanish - Thai - Turkish - Urdu
      </div>

      <img src={AppIcon} alt="My life in the UK" className="logo" />

      <h2>Download Now!</h2>

      <StoreIcons />

      <MobileView viewClassName="watch">
        <div
          onClick={() => window.open("https://youtu.be/DW8BmULd6A8")}
          className="watch-wrapper"
        >
          <img alt="" src={SvgPlay} className="watch-icon" />
          <span>Watch the video</span>
        </div>
      </MobileView>

      <div className="previews">
        {/* <img src={Previews} alt="My life in the UK" className="previewsPng" /> */}
      </div>

      <StoreIcons />

      <div className="footer">
        <a target="_blank" rel="noreferrer" href="/privacy-policy">
          Privacy policy
        </a>
        <a target="_blank" rel="noreferrer" href="/terms-of-service">
          Terms of service
        </a>
      </div>

      <div className="foot-note">
        Handbook is crown copyright has been reproduced from the official
        government publication with the permission of the National Archives
        under the Open Government License.
      </div>
    </div>
  );
}

export default Home;
